import React from 'react';
import BannerImage from "./landing-bg.png";
import BannerImageMobile from "./landing-bg-mobile.png";
import logo from "./logo.png";
import './Landing.css'; 

function Landing() {
  return (
    <div className="container-landing">
      <div className="intro-box">
          <p className='text-intro'>Introducing</p>
          <div className='company-logo'>
            <img className="" src={logo} alt='companyLogo'></img>
          </div>
          <p className='text-approved'>Now approved across Canada!</p>
          <p className='text-not-patient'>Not an actual patient. Talk to your<br/> healthcare provider to learn more.</p>
      </div>
      <div className="image-landing">
          <img  className="person-img" src={BannerImage}  alt=""></img>
          <img  className="person-img-mobile" src={BannerImageMobile}  alt=""></img>
      </div>
    </div>
  );
}

export default Landing;
