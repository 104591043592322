import { React, useState, useEffect } from "react";
import './Main.css'
import needle from "./letybo.mp4";
import placeholder from "./placeholder.png"
import Iframe from 'react-iframe'

const Main = () => {

  //const [page, setPage] = useState(null);

  /*
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/[YOUR_SPACE_ID]/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setPage(data.pageCollection.items[0]);
      });
  }, []);

  if (!page) {
    return "Loading...";
  }
  */

  return (
    <div className="container">
      <div className="approved-font">
        LETYBO is approved and available across Canada!
      </div>
      <main>
        <video className="needle-img" autoPlay muted loop>
          <source src={needle} type="video/mp4" />
          Your browser does not support the video tag.
        </video>        
        <div className="learn-more">
          <p>Learn more about  LETYBO</p>
        </div>
        <div className='medical-font'>
        <p>Just like any medical decision, consult with your healthcare<br/>provider to determine if LETYBO is right for you.</p>
        </div>
        <div className="questions">
            <div className="question-title">Questions to ask your healthcare provider: </div>
          <ul className="subquestions">
            <li>What is LETYBO?</li>
            <li>How does LETYBO work?</li>
            <li>Am I a good candidate for LETYBO?</li>
            <li>What can I expect after treatment with LETYBO?</li>
            <li>Is there anything else I should know about LETYBO?</li>
          </ul>
        </div>
        <div className="find-provider">
          <p>Find a LETYBO provider near you.</p>
        </div>
        <Iframe url="hugel-locator.html" className="locator-placeholder" title="locator">          
        </Iframe>
         
      </main>
    </div>
  )
}

export default Main