import React, { useState } from 'react';
import './Email.css';

const Email = () => {
	const [name, setName] = useState("");
	const [usertype, setUsertype] = useState("I’m a consumer looking for more information");
	const [email, setEmail] = useState("");
	const [iagree, setIagree] = useState("");

	const [errorFields, setErrorFields] = useState([]);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		let error = "";
		let errorFields = [];

		if (usertype == "") {
			errorFields.push("usertype");
			if (error == "")
				error = "Please select who you are.";
		}
		if (name == "") {
			errorFields.push("name");
			if (error == "")
				error = "Name is required";
		}
		if (!isValidEmail(email)) {
			errorFields.push("email");
			if (error == "")
				error = "Invalid email format";
		}

		const formCheckboxIagree = document.querySelector(
			"#iagree"
		);
		if (!formCheckboxIagree.checked) {
			errorFields.push("iagree");
			if (error == "")
				error = "Please read and agree to the privacy statement";
		}

		setSuccess("");
		setErrorFields(errorFields);
		setError(error);
		if (errorFields.length > 0) {
			return;
		}


		//setSuccess("success"); return;// test

		const url = `/send-notification-letybo.php?u=sd9497a54asdfda9dde7c4fdsfasafs5f67sd&id=8rdfg97ca3dcddacsa32assdad2a0s9sdaffvjjgla5kx87id65lmi6565d`;
		const requestOptions = {
			//mode: 'no-cors',
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				usertype, usertype,
				name: name,
				email: email,
				iagree: iagree
			})
		};
		fetch(url, requestOptions)
			.then(response => {
				return response.json()
			})
			.then(data => {
				console.log(data);
				if (data.success == 1)
					setSuccess("Your request has been sent.");
				else
					setError(data.message);
			}).catch(rejected => {
				console.log(rejected);
				setError("System Error");
				//setError(rejected);
			});

	}

	const isValidEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	if (success) {
		//window[`scrollTo`]({ top: 0, behavior: `smooth` })
		return (
			<div className='email-wrapper'>
				<div className='learnMore-title'>Learn More About LETYBO</div>
				<div className='learnMore-description'>Be among the first to learn about upcoming launch activities in your area.</div>
				<br/>
				<div className="success-message">{success}</div>
			</div>
		);
	}

	return (
		<div className='email-wrapper'>
			<div className='learnMore-title'>Learn More About LETYBO</div>
			<div className='learnMore-description'>Be among the first to learn about upcoming launch activities in your area.</div>
			<form>
				<div className='select-wrapper'>
					<label className='select-title'>Select One:</label>
					<select className={"identity-select " + (errorFields.includes('usertype') ? "invalid" : "")} onChange={e => setUsertype(e.target.value)}>
						<option value="I’m a consumer looking for more information">I’m a consumer looking for more information</option>
						<option value="I’m a healthcare professional looking for more information">I’m a healthcare professional looking for more information</option>
					</select>
				</div>
				<div className='info-wrapper'>
					<input className={"info-name " + (errorFields.includes('name') ? "invalid" : "")} type="text" placeholder='Name' onChange={e => setName(e.target.value)} />
					<input className={"info-email " + (errorFields.includes('email') ? "invalid" : "")} type="email" placeholder='Email address' onChange={e => setEmail(e.target.value)} />
				</div>
				<div className='checkbox-wrapper'>
					<input className={"policy-checkbox " + (errorFields.includes('email') ? "invalid" : "")} type="checkbox" id="iagree" onChange={e => e.target.checked ? setIagree(e.target.value) : setIagree("")} />
					<div className='policy-text'>
						<label htmlFor="iagree">I consent to receive email marketing messages. For further information, please visit our&nbsp;<a className='underline-text' href='https://www.hugel-aesthetics.ca/terms-of-use/' target='_blank'>Terms of Use</a>&nbsp;&&nbsp;<a className='underline-text' href='https://www.hugel-aesthetics.ca/privacy-policy/' target='_blank'>Privacy Policy</a>.</label>
					</div>
				</div>
				{error && <div className="error-message">{error}</div>}
				<div className='button-wrapper'>
					<button className='submit-button' type='submit' onClick={handleSubmit}>submit</button>
				</div>
			</form>
		</div>
	);
}

export default Email;