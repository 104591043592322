import React, { useState } from 'react';

const DropdownNavPopup = ({ closeDropdown, openPopup }) => {

  return (
    <div className="hamburger-list">
          <span className="ddn-popup-close" onClick={closeDropdown}>×</span>
          <div className='dropdown'>
            <a href="https://hugel-aesthetics.ca/" target="_blank"><div className="dropdown-content l1">Meet Hugel Aesthetics</div></a>
            <a href="https://www.businesswire.com/news/home/20220623005812/en/Croma-Aesthetics-Canada-Ltd.-Receives-Canadian-Market-Authorization-for-Letybo-LetibotulinumtoxinA-for-Injection-for-the-Treatment-of-Glabellar-Lines" target="_blank"><div className="dropdown-content l2">Press Release</div></a>
            <a href="#" onClick={openPopup}><div className="dropdown-content l3">For Healthcare Professionals</div></a>
          </div>
    </div>
  );
};

export default DropdownNavPopup;