import TagManager from 'react-gtm-module';

import './App.css';
import Email from './Email/Email';
import Footer from './Footer/Footer';
import Landing from './Landing/Landing';
import Main from './Main/Main';
import Navbar from './Navbar/Navbar';
import './App.css'

const tagManagerArgs = {
  gtmId: 'GTM-M32XBHHH'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Landing/>
      <Main/>
      <Email/>
      <Footer/>
    </div>
  );
}

export default App;
