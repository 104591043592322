import hugelLogo from "./hugel-logo.jpeg"
import './Footer.css'
import React from 'react'

const Footer = () => {
  return (
    <div className="container-footer">
        <img className="hugel-logo" src={hugelLogo} alt="hugel-logo"></img>
        <div className="text-footer">
          <p className='text-contact'>CONTACT</p>
          <a href="mailto:example@example.com">infocanada@hugel-aesthetics.ca</a> 
          <p className='text-phone'>1-877-674-5355</p>
          <div className="text-second">
            <p className="copyright">© 2023 HUGEL AESTHETICS</p>
            <p className="trademark">ALL TRADEMARKS ARE THE PROPERTY OF THEIR RESPECTIVE OWNERS.</p>
            <p className="toronto">TORONTO, ONTARIO CANADA M5V 1L9.</p>
            <p className="id">CA-LETI-2300053 09/23</p>
            <p className="privacy-term"><a href='https://www.hugel-aesthetics.ca/privacy-policy/' target='_blank'>PRIVACY NOTICE</a>  |  <a href='https://www.hugel-aesthetics.ca/terms-of-use/' target='_blank'>TERMS OF SERVICES</a></p>
          </div> 
         </div>
    </div>
  )
}

export default Footer