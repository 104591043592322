import React, { useEffect, useState } from 'react';
import { BsList  } from 'react-icons/bs'

import HealthcarePopup from './HealthcarePopup';
import DropdownNavPopup from './DropdownNavPopup';
import logo from "./logo.jpeg"
import './Navbar.css'; 


const Navbar = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
      if (!isPopupOpen) {
          document.body.classList.add('scroll-lock');
      } else {
          document.body.classList.remove('scroll-lock');
      }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      document.body.classList.add('scroll-lock');
    } else {
        document.body.classList.remove('scroll-lock');
    }
  }

  return (
    <nav className="navbar">
      <div className='main-navbar'>
        <div className="left">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="right">
          <ul className="nav-list">
            <li className="nav-item"><a href="https://hugel-aesthetics.ca/" target="_blank">Meet Hugel Aesthetics</a></li>
            <li className="nav-item"><a href="https://www.businesswire.com/news/home/20220623005812/en/Croma-Aesthetics-Canada-Ltd.-Receives-Canadian-Market-Authorization-for-Letybo-LetibotulinumtoxinA-for-Injection-for-the-Treatment-of-Glabellar-Lines" target="_blank">Press Release</a></li>
            <li className="nav-item"><a href="#" onClick={togglePopup}>For Healthcare Professionals</a></li>
          </ul>
          <div className='dropdown-button' >
            <BsList className='hamburger-icon' size="40px" color="black" onClick={toggleDropdown}/>
          </div>
        </div>
      </div>
      {dropdownOpen && <DropdownNavPopup closeDropdown={toggleDropdown} openPopup={togglePopup}/>}
      {isPopupOpen && <HealthcarePopup closePopup={togglePopup} />} 
    </nav>
  );
};

export default Navbar;

{/* <div className="dropdown-content l1"><a href="#">Meet Hugel Aesthetics</a></div>
            <div className="dropdown-content l2"><a href="#">Press Release</a></div>
            <div className="dropdown-content l3"><a href="#" onClick={togglePopup}>For Healthcare Professionals</a></div> */}

