import React, { useState } from 'react';

const HealthcarePopup = ({ closePopup }) => {
  const [din, setDin] = useState('');
  const [error, setError] = useState("");

  const handleDinChange = (e) => {
    setDin(e.target.value);
  };

  const handleSubmit = async (e,closePopup) => {
		e.preventDefault();

    if(din.trim()!=="02528142"
      && din.trim()!=="2528142"){
      setError("Please enter DIN.");
      return;
    }

    closePopup();
    const link = document.createElement("a");
        link.href = "LETYBO Product Monograph.pdf";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();  
        document.body.removeChild(link);      
        /* download
        link.href = "LETYBO Product Monograph.pdf";
        link.download = "LETYBO Product Monograph.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);*/
  }

  return (
    <div className="popup-backdrop">
      <div className="healthcare-popup">
        <div className="popup-content">
          <span className="popup-close" onClick={closePopup}>×</span>
          <p className='thickness'>For Health Care Professionals</p>
          
          <div className="din-input">
            <label htmlFor="din">Enter DIN: </label>
            <input
              type="text"
              id="din"
              value={din}
              onChange={handleDinChange}
              placeholder='[Password]'
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button onClick={(e)=>{handleSubmit(e,closePopup);}}>SUBMIT</button>
        </div>
      </div>
    </div>
  );
};

export default HealthcarePopup;